// Core
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

// Actions
import { setSortByBest } from "redux/actions/daily-actions";

// Components
import { Card, CardContent, Typography, Box, Drawer } from "@material-ui/core";

// Icons
import { ReactComponent as StoreIcon } from "theme/icons/store.svg";
import { ReactComponent as ManagerIcon } from "theme/icons/manager-icon.svg";
import { ReactComponent as SortIcon } from "theme/icons/sort-desc-icon.svg";
import { ReactComponent as AddressIcon } from "theme/icons/address.svg";

// Tools
import { formatStatsValue, getDailyDateFromTimestamp } from "utils/helpers";
import {
  DAILY_STATS_FIELDS,
  DAILY_STATS_FIELDS_WITHOUT_RULE,
  COMPANY_ID,
} from "utils/constants";

// Styles
import { useStyles } from "./daily-store-list.styles";

const DailyStoreList = ({ stores, activeTab, data }) => {
  console.log(activeTab);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sortByBest, filterBy } = useSelector((state) => state.daily);
  const ID = useSelector((state) => state?.auth?.user?.company_id);
  const IS_BOJANGLES = ID === COMPANY_ID.BOJANGLES;
  const companyId = data?.stores?.length > 0 ? data?.stores[0]?.company_id : "";
  const totalMetrics =
    filterBy === "store"
      ? data?.stores?.find((store) => store.id === "total")
      : data?.managers?.find((store) => store.id === "total");
  const isFilteredByManager = filterBy === "manager";
  const isFilteredByStore = filterBy === "store";
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTotalDrawerOpen, setIsTotalDrawerOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const currentTabValue = IS_BOJANGLES
    ? DAILY_STATS_FIELDS_WITHOUT_RULE[activeTab]
    : DAILY_STATS_FIELDS[activeTab];
  const SORT_BY_NAME = IS_BOJANGLES
    ? {
        0: "Upsell",
        1: "SoS",
        2: "Ops",
        3: "Awards",
      }
    : {
        0: "Upsell",
        1: "SoS",
        2: "Rule",
        3: "Ops",
        4: "Awards",
      };

  const drawerEventHandler = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
  };

  const toggleStoreDrawer = (open, store) => (event) => {
    drawerEventHandler(event);
    if (store) {
      setSelectedStore(store);
    }
    setIsDrawerOpen(open);
  };

  const toggleTotalDrawer = (open) => (event) => {
    drawerEventHandler(event);
    setIsTotalDrawerOpen(open);
  };

  const toggleSort = () => {
    dispatch(setSortByBest(!sortByBest));
  };

  return (
    <>
      <Box className={classes.filters}>
        <Box className={classes.sort} onClick={toggleSort}>
          <Typography className={classes.sortTitle} variant="caption">
            Sort by:
          </Typography>
          <Typography className={classes.sortValue} variant="caption">
            {sortByBest ? "Top" : "Bottom"} {SORT_BY_NAME[activeTab]}
            <SortIcon className={classes.sortIcon} />
          </Typography>
        </Box>

        <Box className={classes.totalWeeklyRow}>
          <Typography className={classes.totalWeeklyTitle} variant="body1">
            Total: {formatStatsValue(totalMetrics?.total, activeTab, companyId)}
          </Typography>
          <Typography
            className={classes.totalWeeklyLink}
            onClick={toggleTotalDrawer(true)}
            variant="body1"
          >
            Go To Weekly Total
          </Typography>
        </Box>
      </Box>

      {stores && (
        <div className={classes.cardList}>
          {stores?.map((store) => {
            if (store.id === "total") return false;
            return (
              <Card
                key={store?.id || store?.name}
                className={classes.card}
                onClick={toggleStoreDrawer(true, store)}
                variant="outlined"
              >
                <CardContent className={classes.cardContent}>
                  {isFilteredByStore && (
                    <Box className={classes.storeRow}>
                      <StoreIcon className={classes.storeIcon} />
                      <Typography className={classes.storeName}>
                        {store?.store_name}
                      </Typography>
                    </Box>
                  )}
                  {isFilteredByManager && (
                    <Box className={classes.storeRow}>
                      <ManagerIcon className={classes.storeIcon} />
                      <Typography className={classes.storeName}>
                        {store?.name}
                      </Typography>
                    </Box>
                  )}

                  {isFilteredByStore && (
                    <Box className={classes.managerWrapper}>
                      {store?.manager_district_name && (
                        <Box className={classes.managerRow}>
                          <ManagerIcon className={classes.managerIcon} />
                          <Typography className={classes.managerName}>
                            {store?.manager_district_name}
                          </Typography>
                        </Box>
                      )}
                      <Box className={classes.addressRow}>
                        <AddressIcon className={classes.addressIcon} />
                        <Typography className={classes.addressText}>
                          {store?.city}, {store?.state}
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  <Box className={classes.metricRow}>
                    <Typography className={classes.metric} variant="body2">
                      Yesterday:{" "}
                      <span className={classes.metricValue}>
                        {formatStatsValue(
                          store?.yesterday,
                          activeTab,
                          companyId
                        )}
                      </span>
                    </Typography>
                    <Typography className={classes.metric} variant="body2">
                      {currentTabValue === "ops_alert_count" ||
                      currentTabValue === "good_alert_count" ||
                      currentTabValue === "ops_sos_order_count"
                        ? "Total"
                        : "Average"}
                      :{" "}
                      <span className={classes.metricValue}>
                        {formatStatsValue(store?.total, activeTab, companyId)}
                      </span>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            );
          })}
        </div>
      )}

      <Drawer
        className={classes.drawer}
        anchor="bottom"
        open={isDrawerOpen}
        onClose={toggleStoreDrawer(false)}
      >
        {selectedStore && (
          <>
            <Box className={classes.drawerHeader}>
              {isFilteredByStore && (
                <Box className={classes.storeRow}>
                  <StoreIcon
                    className={clsx(classes.storeIcon, classes.drawerStoreIcon)}
                  />
                  <Typography
                    className={clsx(classes.storeName, classes.drawerStoreName)}
                  >
                    {selectedStore?.store_name}
                  </Typography>
                </Box>
              )}

              {isFilteredByManager && (
                <Box className={classes.storeRow}>
                  <ManagerIcon
                    className={clsx(classes.storeIcon, classes.drawerStoreIcon)}
                  />
                  <Typography
                    className={clsx(classes.storeName, classes.drawerStoreName)}
                  >
                    {selectedStore?.name}
                  </Typography>
                </Box>
              )}

              {isFilteredByStore && (
                <Box className={clsx(classes.addressRow, classes.drawerRow)}>
                  <AddressIcon className={classes.drawerAddressIcon} />
                  <Typography className={classes.drawerAddressText}>
                    {selectedStore?.city}, {selectedStore?.state}
                  </Typography>
                </Box>
              )}

              {selectedStore?.manager_district_name && isFilteredByStore && (
                <Box className={clsx(classes.managerRow, classes.drawerRow)}>
                  <ManagerIcon className={classes.managerIcon} />
                  <Typography className={classes.managerName}>
                    {selectedStore?.manager_district_name}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box className={classes.drawerContent}>
              {selectedStore?.stats.map((stat) => (
                <Box key={stat?.date} className={classes.statRow}>
                  <Typography
                    className={clsx(
                      classes.statDate,
                      stat?.date === "total" && classes.statDateTotal
                    )}
                    variant="body1"
                  >
                    {getDailyDateFromTimestamp(stat?.date)}
                  </Typography>
                  <Typography
                    className={clsx(
                      classes.statValue,
                      stat?.date === "total" && classes.statValueTotal
                    )}
                    variant="body1"
                  >
                    {formatStatsValue(stat, activeTab, companyId)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </>
        )}
      </Drawer>

      <Drawer
        className={classes.drawer}
        anchor="bottom"
        open={isTotalDrawerOpen}
        onClose={toggleTotalDrawer(false)}
      >
        {totalMetrics && (
          <>
            <Box className={classes.drawerHeader}>
              <Box className={classes.totalRow}>
                <Typography className={classes.totalTitle} variant="body1">
                  Total:
                </Typography>
                <Typography className={classes.totalValue} variant="body1">
                  {formatStatsValue(totalMetrics.total, activeTab, companyId)}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.drawerContent}>
              {totalMetrics?.stats.map((stat) => {
                if (stat?.date === "total") return false;
                return (
                  <Box key={stat?.date} className={classes.statRow}>
                    <Typography className={classes.statDate} variant="body1">
                      {getDailyDateFromTimestamp(stat?.date)}
                    </Typography>
                    <Typography className={classes.statValue} variant="body1">
                      {formatStatsValue(stat, activeTab, companyId)}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Drawer>
    </>
  );
};

export default DailyStoreList;
