// Core
import moment from "moment-timezone";
import parse from "html-react-parser";
import * as d3 from "d3";

// Constants
import {
  METRICS,
  METRICS_WITH_FORMAT,
  DAILY_STATS_FIELDS,
  DAILY_STATS_FIELDS_WITHOUT_RULE,
  COMPANY_ID,
} from "./constants";

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function getDateFromTimestamp(timestamp) {
  if (timestamp) {
    const parsed = moment.parseZone(timestamp);
    const dateParts = parsed.format("MM/DD/YYYY");
    const timeParts = parsed.format("hh:mm A");

    return `${dateParts} at ${timeParts}`;
  }

  return "";
}

function getTimeFromTimestamp(timestamp) {
  if (timestamp) {
    const parsed = moment.parseZone(timestamp);
    const timeParts = parsed.format("hh:mm A");

    return `${timeParts}`;
  }

  return "";
}

function getAmpmFromTimestamp(timestamp) {
  if (timestamp) {
    const date = moment(timestamp).format("MM/DD");
    const time = moment(timestamp).format("hh:mm A");

    return `${date} ${time}`;
  }
}

function getAmpmWithTimezone(timestamp, timezone) {
  if (timestamp && timezone) {
    const date = moment(timestamp).tz(timezone).format("MM/DD");
    const time = moment(timestamp).tz(timezone).format("hh:mm A");

    return `${date} ${time}`;
  }
}

function secToMinutes(timeInSeconds) {
  const pad = function (num, size) {
    return ("000" + num).slice(size * -1);
  };
  let time = parseFloat(timeInSeconds).toFixed(3);
  let minutes = Math.floor(time / 60) % 60;
  let seconds = Math.floor(time - minutes * 60);

  return pad(minutes, 2) + ":" + pad(seconds, 2);
}

const formatNumber = (value) => (value ? d3.format(".0f")(value) : "");
const formatRank = (value) => (value ? d3.format(".1f")(value) : "");
const formatCurrency = (value) => (value ? d3.format("($,.0f")(value) : "");
const formatRate = (value) => (value ? d3.format(".0%")(value) : "");
const formatUpsell = (value) => (value >= 0 ? value.toFixed(2) : "");
const formatTimes = (value) => (value ? `${d3.format(".2f")(value)}x` : "");

function formatDate(timestamp) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(timestamp);
  let day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  day = day < 10 ? `0${day}` : day;

  return `${day} ${month}, ${year}`;
}

function lagDay(value) {
  let date_from = new Date();
  let date_to = new Date();

  if (value === "today") {
    date_from.setDate(date_from.getDate());
    date_to.setDate(date_to.getDate());
  }

  if (value === "yesterday") {
    date_from.setDate(date_from.getDate() - 1);
    date_to.setDate(date_to.getDate() - 1);
  }

  if (value === "week") {
    date_from.setDate(date_from.getDate() - 8);
    date_to.setDate(date_to.getDate() - 1);
  }

  if (value === "month") {
    date_from.setDate(date_from.getDate() - 31);
    date_to.setDate(date_to.getDate() - 1);
  }

  const year_from = date_from.getFullYear();
  const year_to = date_to.getFullYear();
  const month_from = `${date_from.getMonth() + 1}`.padStart(2, "0");
  const month_to = `${date_to.getMonth() + 1}`.padStart(2, "0");
  const day_from = `${date_from.getDate()}`.padStart(2, "0");
  const day_to = `${date_to.getDate()}`.padStart(2, "0");

  date_from = `${year_from}-${month_from}-${day_from} 00:00:00`;
  date_to = `${year_to}-${month_to}-${day_to} 23:59:59`;

  return { date_from, date_to };
}

function searchBy(values, filterBy) {
  const normalizedValue = (value) =>
    value.toLowerCase().trim().split(" ").join("");
  const normalizedFilterBy = normalizedValue(filterBy);

  if (values) {
    return Object.keys(values).some(
      (key) =>
        values[key] && normalizedValue(values[key]).includes(normalizedFilterBy)
    );
  }
}

function prettifyMetric(metric) {
  return METRICS.hasOwnProperty(metric) ? METRICS[metric] : metric;
}

function formatMetricValues(key, value) {
  let formattedValue = "";
  METRICS_WITH_FORMAT.forEach((obj) => {
    const format = Object.keys(obj)[0] === key ? obj.format : "";
    if (format === "rate") formattedValue = formatRate(value);
    if (format === "dollar") formattedValue = formatCurrency(value);
    if (format === "number") formattedValue = formatNumber(value);
    if (format === "string") formattedValue = value;
  });
  return formattedValue;
}

function capitalizeString(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getFirstLettersFromUserName({ first_name, last_name }) {
  if (first_name && last_name) {
    return `${first_name.charAt(0).toUpperCase()}${last_name
      .charAt(0)
      .toUpperCase()}`;
  }
  if (first_name && !last_name) {
    return `${first_name.charAt(0).toUpperCase()}`;
  }
}

function prettyDuration(duration) {
  const sec = parseInt(duration, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${hours}:${minutes}h`;
}

const formatEntities = (item) => {
  const { text, entities } = item;

  entities.sort((a, b) => (a.start < b.start ? -1 : 1));

  let formattedString = "";
  let cur = 0;

  entities.forEach(({ start, end, type }) => {
    if (start > cur) {
      formattedString += text.slice(cur, start - 1).trim() + " ";
    }
    formattedString += `<span class="${type}">${text
      .slice(Math.max(0, start - 1), end + 1)
      .trim()}</span> `;
    cur = end + 1;
  });

  formattedString += text.slice(cur).trim();

  return parse(formattedString);
};

function appHeight() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

function addClassToBody(className) {
  document.body.classList.add(className);
}

function removeClassFromBody(className) {
  document.body.classList.remove(className);
}

function getPlotFormat(format) {
  if (format === "dollar") {
    return d3.format("($,.0f");
  } else if (format === "rate") {
    return d3.format(".0%");
  } else if (format === "times") {
    return (d) => `${d3.format(".2f")(d)}x`;
  } else {
    return d3.format(".2f");
  }
}

function validatePhoneNumber(value) {
  const formattedValue = value.replace(/[()]|-|_| /g, "");

  // Mask if filled
  if (formattedValue.length === 12)
    return { value: formattedValue, isValid: true };
  // Mask is empty
  if (formattedValue.length === 2 || formattedValue.length === 0)
    return { value: null, isValid: true };

  return { value: formattedValue, isValid: false };
}

function formatPhoneNumber(number) {
  let format = "xx (xxx) xxx-xxxx";

  for (let i = 0; i < number.length; i++) {
    format = format.replace("x", number[i]);
  }

  return format;
}

function convertTimeTo12Hours(hours) {
  const hoursNum = Number(hours);
  const time = hoursNum > 12 ? hoursNum - 12 : hoursNum;
  return time < 10 ? Number(`0${time}`) : time;
}

function convertTimeTo24Hours(hours) {
  const hoursNum = Number(hours);
  const time = hoursNum <= 12 ? hoursNum + 12 : hoursNum;
  return time < 10 ? Number(`0${time}`) : time;
}

function getAmPmFromHours(hours) {
  return hours === 24 || hours < 12 ? "am" : "pm";
}

function toIsoFormat(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function getMonthByValue(value) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return value > 0 ? months[value - 1] : "";
}

function calculateDuration(timestamp, date_start) {
  if (!timestamp || !date_start) {
    return "00:00h";
  }
  const date = new Date(timestamp);
  const dateStart = new Date(date_start);
  const milliseconds = Math.abs(date.getTime() - dateStart.getTime());

  let minutes = parseInt((milliseconds / (1000 * 60)) % 60);
  let hours = parseInt((milliseconds / (1000 * 60 * 60)) % 24);
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes}h`;
}

const formatStatsValue = (stat, tab, companyId) => {
  const IS_BOJANGLES = companyId === COMPANY_ID.BOJANGLES;
  const fieldNameByActiveTab = IS_BOJANGLES
    ? DAILY_STATS_FIELDS_WITHOUT_RULE[tab]
    : DAILY_STATS_FIELDS[tab];
  const value = stat[fieldNameByActiveTab];
  const isBroadway =
    fieldNameByActiveTab === "total_upsell_rate" &&
    companyId === COMPANY_ID.BROADWAY;

  if (!stat?.is_valid || value <= 0.0 || !value) {
    return "-";
  } else if (isBroadway) {
    return value?.toFixed(2);
  } else if (fieldNameByActiveTab.endsWith("_rate")) {
    return `${Math.floor(value * 100)}%`;
  }
  return Math.floor(value);
};

const getDailyDateFromTimestamp = (timestamp) => {
  if (timestamp === "total") return "Total";
  if (timestamp) {
    const parsed = moment.parseZone(timestamp);
    const date = parsed.format("MM/DD");
    const day = parsed.format("ddd");

    return `${date} ${day}`;
  }
  return timestamp;
};

export {
  isEmpty,
  secToMinutes,
  formatNumber,
  formatCurrency,
  formatRate,
  formatUpsell,
  formatTimes,
  formatRank,
  formatDate,
  lagDay,
  searchBy,
  prettifyMetric,
  formatMetricValues,
  capitalizeString,
  getDateFromTimestamp,
  getAmpmFromTimestamp,
  getAmpmWithTimezone,
  getFirstLettersFromUserName,
  prettyDuration,
  formatEntities,
  appHeight,
  addClassToBody,
  removeClassFromBody,
  getPlotFormat,
  validatePhoneNumber,
  formatPhoneNumber,
  convertTimeTo12Hours,
  convertTimeTo24Hours,
  getAmPmFromHours,
  getMonthByValue,
  toIsoFormat,
  getTimeFromTimestamp,
  calculateDuration,
  formatStatsValue,
  getDailyDateFromTimestamp,
};
