// Icons
import { ReactComponent as AlertGuestServiceIcon } from "theme/icons/alert-guest-service.svg"; // Trophy
import { ReactComponent as AlertSayMyNameIcon } from "theme/icons/alert-good-say-name.svg"; // Rice
import { ReactComponent as AlertUpsellOfferingIcon } from "theme/icons/alert-upsell-offering.svg"; // Chief
import { ReactComponent as AlertWarmestWelcomeIcon } from "theme/icons/alert-warmest-welcome.svg"; // Cat with coffee
import { ReactComponent as AlertPleaseMachineIcon } from "theme/icons/alert-please-machine.svg"; // Cosmonaut
import { ReactComponent as AlertNoOpsIssuesIcon } from "theme/icons/alert-no-issues.svg"; // Pizza
import { ReactComponent as AlertNoOpsIssuesStreakIcon } from "theme/icons/alert-no-issues-streak.svg"; // Pizza Sticker

const METRICS = {
  store_cnt: "Store count",
  store_name: "Store number",
  manager_district_name: "District manager",
  conversation_cnt: "Conversation count",
  upsell_offering_rate: "Upsell offering",
  est_upsell_revenue: "Upsell revenue",
  upsize_offering_rate: "Upsize offering",
  upsize_success_rate: "Upsize success",
  cross_sell_offering_rate: "X-sell offering",
  cross_sell_success_rate: "X-sell success",
  addon_offering_rate: "Add-on offering",
  addon_success_rate: "Add-on success",
  est_ops_lost_revenue: "Lost revenue",
  ops_total_cnt: "Issue total",
  ops_issue_rate: "Issue rate",
  ops_out_of_product_cnt: "Out of product",
  ops_broken_shake_cnt: "Broken shake",
  ops_broiler_down_cnt: "Broiler down",
  ops_credit_card_down_cnt: "PIN pads down",
  ops_ice_cream_down_cnt: "Broken ice-cream",
  ops_system_down_cnt: "System down",
  ops_other_cnt: "Ops other",
  ops_cant_hear_cnt: "Can't hear",
  utterance_cnt: "Utterance count",
  good_overall_experience: "Great guest service",
  good_warm_welcome: "You rule welcome",
  good_thankyou_machine: "Thank you & please machine",
  good_upsell_offering: "Upsell master",
  good_say_my_name: "Personal touch",
  good_no_ops_issues: "Excellent Operations",
  good_no_ops_issues_streak: "Excellent Operations streak",
};

const METRICS_WITH_FORMAT = [
  { store_cnt: "Store count", format: "number" },
  { store_name: "Store number", format: "string" },
  { manager_district_name: "District manager", format: "string" },
  { conversation_cnt: "Conversation count", format: "number" },
  { upsell_offering_rate: "Upsell offering", format: "rate" },
  { est_upsell_revenue: "Upsell revenue", format: "dollar" },
  { upsize_offering_rate: "Upsize offering", format: "rate" },
  { upsize_success_rate: "Upsize success", format: "rate" },
  { cross_sell_offering_rate: "X-sell offering", format: "rate" },
  { cross_sell_success_rate: "X-sell success", format: "rate" },
  { addon_offering_rate: "Add-on offering", format: "rate" },
  { addon_success_rate: "Add-on success", format: "rate" },
  { est_ops_lost_revenue: "Lost revenue", format: "dollar" },
  { ops_total_cnt: "Issue total", format: "number" },
  { ops_issue_rate: "Issue rate", format: "rate" },
  { ops_out_of_product_cnt: "Out of product", format: "number" },
  { ops_broken_shake_cnt: "Broken shake", format: "number" },
  { ops_broiler_down_cnt: "Broiler down", format: "number" },
  { ops_credit_card_down_cnt: "PIN pads down", format: "number" },
  { ops_ice_cream_down_cnt: "Broken ice-cream", format: "number" },
  { ops_system_down_cnt: "System down", format: "number" },
  { ops_other_cnt: "Ops other", format: "number" },
  { ops_cant_hear_cnt: "Can't hear", format: "number" },
  { utterance_cnt: "Utterance count", format: "number" },
];

const GOOD_ALERT_ICONS = {
  good_overall_experience: (
    <AlertGuestServiceIcon className="alert-good-icon" />
  ),
  good_say_my_name: <AlertSayMyNameIcon className="alert-good-icon" />,
  good_upsell_offering: <AlertUpsellOfferingIcon className="alert-good-icon" />,
  good_warm_welcome: <AlertWarmestWelcomeIcon className="alert-good-icon" />,
  good_thankyou_machine: <AlertPleaseMachineIcon className="alert-good-icon" />,
  good_no_ops_issues: <AlertNoOpsIssuesIcon className="alert-good-icon" />,
  good_no_ops_issues_streak: (
    <AlertNoOpsIssuesStreakIcon className="alert-good-icon" />
  ),
};

const DAILY_STATS_FIELDS = {
  0: "total_upsell_rate",
  1: "ops_sos_order_count",
  2: "rule_welcome_rate",
  3: "ops_alert_count",
  4: "good_alert_count",
};
const DAILY_STATS_FIELDS_WITHOUT_RULE = {
  0: "total_upsell_rate",
  1: "ops_sos_order_count",
  2: "ops_alert_count",
  3: "good_alert_count",
};

const COMPANY_ID = {
  BROADWAY: "6455448b-6b2e-4e7d-999b-e13a89170b91",
  BOJANGLES: "2e152db5-6cde-4741-b440-97f360964785",
};

const CHAT_MESSAGES_PER_PAGE = 50;

export {
  METRICS,
  METRICS_WITH_FORMAT,
  GOOD_ALERT_ICONS,
  DAILY_STATS_FIELDS,
  DAILY_STATS_FIELDS_WITHOUT_RULE,
  COMPANY_ID,
  CHAT_MESSAGES_PER_PAGE,
};
