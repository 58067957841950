// Core
import React from "react";
import moment from "moment";

// Components
import { MuiHeaderCell } from "components/common";
import { Grid, Typography } from "@material-ui/core";

// Tools
import { COMPANY_ID } from "utils/constants";

const DAYS_OF_WEEK = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
  AVERAGE: 7,
};

const METADATA = {
  0: {
    title: "Upsell %",
    info: "Upsell %",
    col: "total_upsell_rate",
  },
  1: {
    title: "# of SoS delays",
    info: "# of SoS delays",
    col: "ops_sos_order_count",
  },
  2: {
    title: "You rule %",
    info: "You rule %",
    col: "rule_welcome_rate",
  },
  3: {
    title: "# of alerts",
    info: "# of alerts",
    col: "ops_alert_count",
  },
  4: {
    title: "# of awards",
    info: "# of awards",
    col: "good_alert_count",
  },
  5: {
    title: "My name is %",
    info: "My name is %",
    col: "say_my_name_rate",
  },
};
const METADATA_WITHOUT_RULE = {
  0: {
    title: "Upsell %",
    info: "Upsell %",
    col: "total_upsell_rate",
  },
  1: {
    title: "# of SoS delays",
    info: "# of SoS delays",
    col: "ops_sos_order_count",
  },
  2: {
    title: "# of alerts",
    info: "# of alerts",
    col: "ops_alert_count",
  },
  3: {
    title: "# of awards",
    info: "# of awards",
    col: "good_alert_count",
  },
  4: {
    title: "My name is %",
    info: "My name is %",
    col: "say_my_name_rate",
  },
};

const getDateFromTimestamp = (timestamp) => {
  if (timestamp) {
    const parsed = moment.parseZone(timestamp);
    const date = parsed.format("MM/DD");
    const day = parsed.format("ddd");

    return `${date} ${day}`;
  }
  return timestamp;
};

const formatTotalUpsellValue = (value) => {
  if (!value) {
    return "-";
  }
  const int = value.includes("%") ? parseInt(value.split("%")[0]) : value;
  return int / 100;
};

const formatTableValue = (row, col, companyId) => {
  const value = row[col];
  const isBroadway =
    col === "total_upsell_rate" && companyId === COMPANY_ID.BROADWAY;
  if (!row?.is_valid || row[col] <= 0.0 || !value) {
    return "-";
  } else if (isBroadway) {
    return value?.toFixed(2);
  } else if (col.endsWith("_rate")) {
    return `${Math.floor(value * 100)}%`;
  }
  return Math.floor(value);
};

const getTableTitle = (metric, companyId) => {
  const IS_BOJANGLES = COMPANY_ID.BOJANGLES === companyId;
  return IS_BOJANGLES
    ? METADATA_WITHOUT_RULE[metric]?.title
    : METADATA[metric]?.title;
};

const getTableColumns = (metric, companyId) => {
  const IS_BOJANGLES = COMPANY_ID.BOJANGLES === companyId;
  const { col, info } = IS_BOJANGLES
    ? METADATA_WITHOUT_RULE[metric]
    : METADATA[metric];
  return [
    {
      Header: () => (
        <MuiHeaderCell
          title="Store number"
          tooltipTitle="Restaurant number in the system."
        />
      ),
      accessor: "store_name",
      width: 200,
      Cell: (props) => {
        const { isTotal, store_name, manager_district_name, city, state } =
          props.row.original;
        return (
          <Grid
            container
            wrap="nowrap"
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {isTotal && (
              <Typography variant="body2">
                <b style={{ fontFamily: "RedHatBold", fontSize: 14 }}>Total</b>
              </Typography>
            )}
            <Typography variant="body2">
              <b>{store_name}</b>
            </Typography>
            {manager_district_name ? (
              <Typography variant="body2">
                <b>{manager_district_name}</b>
              </Typography>
            ) : (
              <Typography variant="body2">
                <b>
                  {city} {state}
                </b>
              </Typography>
            )}
          </Grid>
        );
      },
    },
    {
      Header: ({ data }) => {
        const currentDate = data[0]?.stats[DAYS_OF_WEEK.MONDAY]?.date;
        return (
          <MuiHeaderCell
            title={getDateFromTimestamp(currentDate) || "-"}
            tooltipTitle={`${info} at ${moment(currentDate).format(
              "MM/DD/YY"
            )}`}
          />
        );
      },
      id: "monday",
      accessor: (d) => {
        return formatTableValue(
          d?.stats[DAYS_OF_WEEK.MONDAY],
          col,
          d?.company_id
        );
      },
      Cell: (props) => {
        const { isTotal, company_id } = props.row.original;
        const companyId = isTotal ? props?.data[0]?.company_id : company_id;
        const isBroadway =
          col === "total_upsell_rate" && companyId === COMPANY_ID.BROADWAY;
        return (
          <>
            {isTotal ? (
              <Typography variant="body2" style={{ fontFamily: "RedHatBold" }}>
                {isBroadway
                  ? formatTotalUpsellValue(props?.value)
                  : props.value}
              </Typography>
            ) : (
              <>{props.value}</>
            )}
          </>
        );
      },
    },
    {
      Header: ({ data }) => {
        const currentDate = data[0]?.stats[DAYS_OF_WEEK.TUESDAY]?.date;
        return (
          <MuiHeaderCell
            title={getDateFromTimestamp(currentDate) || "-"}
            tooltipTitle={`${getTableTitle(metric)} at ${moment(
              currentDate
            ).format("MM/DD/YY")}`}
          />
        );
      },
      id: "tuesday",
      accessor: (d) => {
        return formatTableValue(
          d?.stats[DAYS_OF_WEEK.TUESDAY],
          col,
          d?.company_id
        );
      },
      Cell: (props) => {
        const { isTotal, company_id } = props.row.original;
        const companyId = isTotal ? props?.data[0]?.company_id : company_id;
        const isBroadway =
          col === "total_upsell_rate" && companyId === COMPANY_ID.BROADWAY;
        return (
          <>
            {isTotal ? (
              <Typography variant="body2" style={{ fontFamily: "RedHatBold" }}>
                {isBroadway
                  ? formatTotalUpsellValue(props?.value)
                  : props.value}
              </Typography>
            ) : (
              <>{props.value}</>
            )}
          </>
        );
      },
    },
    {
      Header: ({ data }) => {
        const currentDate = data[0]?.stats[DAYS_OF_WEEK.WEDNESDAY]?.date;
        return (
          <MuiHeaderCell
            title={getDateFromTimestamp(currentDate) || "-"}
            tooltipTitle={`${info} at ${moment(currentDate).format(
              "MM/DD/YY"
            )}`}
          />
        );
      },
      id: "wednesday",
      accessor: (d) => {
        return formatTableValue(
          d?.stats[DAYS_OF_WEEK.WEDNESDAY],
          col,
          d?.company_id
        );
      },
      Cell: (props) => {
        const { isTotal, company_id } = props.row.original;
        const companyId = isTotal ? props?.data[0]?.company_id : company_id;
        const isBroadway =
          col === "total_upsell_rate" && companyId === COMPANY_ID.BROADWAY;
        return (
          <>
            {isTotal ? (
              <Typography variant="body2" style={{ fontFamily: "RedHatBold" }}>
                {isBroadway
                  ? formatTotalUpsellValue(props?.value)
                  : props.value}
              </Typography>
            ) : (
              <>{props.value}</>
            )}
          </>
        );
      },
    },
    {
      Header: ({ data }) => {
        const currentDate = data[0]?.stats[DAYS_OF_WEEK.THURSDAY]?.date;
        return (
          <MuiHeaderCell
            title={getDateFromTimestamp(currentDate) || "-"}
            tooltipTitle={`${info} at ${moment(currentDate).format(
              "MM/DD/YY"
            )}`}
          />
        );
      },
      id: "thursday",
      accessor: (d) => {
        return formatTableValue(
          d?.stats[DAYS_OF_WEEK.THURSDAY],
          col,
          d?.company_id
        );
      },
      Cell: (props) => {
        const { isTotal, company_id } = props.row.original;
        const companyId = isTotal ? props?.data[0]?.company_id : company_id;
        const isBroadway =
          col === "total_upsell_rate" && companyId === COMPANY_ID.BROADWAY;
        return (
          <>
            {isTotal ? (
              <Typography variant="body2" style={{ fontFamily: "RedHatBold" }}>
                {isBroadway
                  ? formatTotalUpsellValue(props?.value)
                  : props.value}
              </Typography>
            ) : (
              <>{props.value}</>
            )}
          </>
        );
      },
    },
    {
      Header: ({ data }) => {
        const currentDate = data[0]?.stats[DAYS_OF_WEEK.FRIDAY]?.date;
        return (
          <MuiHeaderCell
            title={getDateFromTimestamp(currentDate) || "-"}
            tooltipTitle={`{info} at ${moment(currentDate).format("MM/DD/YY")}`}
          />
        );
      },
      id: "friday",
      accessor: (d) => {
        return formatTableValue(
          d?.stats[DAYS_OF_WEEK.FRIDAY],
          col,
          d?.company_id
        );
      },
      Cell: (props) => {
        const { isTotal, company_id } = props.row.original;
        const companyId = isTotal ? props?.data[0]?.company_id : company_id;
        const isBroadway =
          col === "total_upsell_rate" && companyId === COMPANY_ID.BROADWAY;
        return (
          <>
            {isTotal ? (
              <Typography variant="body2" style={{ fontFamily: "RedHatBold" }}>
                {isBroadway
                  ? formatTotalUpsellValue(props?.value)
                  : props.value}
              </Typography>
            ) : (
              <>{props.value}</>
            )}
          </>
        );
      },
    },
    {
      Header: ({ data }) => {
        const currentDate = data[0]?.stats[DAYS_OF_WEEK.SATURDAY]?.date;
        return (
          <MuiHeaderCell
            title={getDateFromTimestamp(currentDate) || "-"}
            tooltipTitle={`${info} at ${moment(currentDate).format(
              "MM/DD/YY"
            )}`}
          />
        );
      },
      id: "saturday",
      accessor: (d) => {
        return formatTableValue(
          d?.stats[DAYS_OF_WEEK.SATURDAY],
          col,
          d?.company_id
        );
      },
      Cell: (props) => {
        const { isTotal, company_id } = props.row.original;
        const companyId = isTotal ? props?.data[0]?.company_id : company_id;
        const isBroadway =
          col === "total_upsell_rate" && companyId === COMPANY_ID.BROADWAY;
        return (
          <>
            {isTotal ? (
              <Typography variant="body2" style={{ fontFamily: "RedHatBold" }}>
                {isBroadway
                  ? formatTotalUpsellValue(props?.value)
                  : props.value}
              </Typography>
            ) : (
              <>{props.value}</>
            )}
          </>
        );
      },
    },
    {
      Header: ({ data }) => {
        const currentDate = data[0]?.stats[DAYS_OF_WEEK.SUNDAY]?.date;
        return (
          <MuiHeaderCell
            title={getDateFromTimestamp(currentDate) || "-"}
            tooltipTitle={`${info} at ${moment(currentDate).format(
              "MM/DD/YY"
            )}`}
          />
        );
      },
      id: "sunday",
      accessor: (d) => {
        return formatTableValue(
          d?.stats[DAYS_OF_WEEK.SUNDAY],
          col,
          d?.company_id
        );
      },
      Cell: (props) => {
        const { isTotal, company_id } = props.row.original;
        const companyId = isTotal ? props?.data[0]?.company_id : company_id;
        const isBroadway =
          col === "total_upsell_rate" && companyId === COMPANY_ID.BROADWAY;
        return (
          <>
            {isTotal ? (
              <Typography variant="body2" style={{ fontFamily: "RedHatBold" }}>
                {isBroadway
                  ? formatTotalUpsellValue(props?.value)
                  : props.value}
              </Typography>
            ) : (
              <>{props.value}</>
            )}
          </>
        );
      },
    },
    {
      Header: ({ data }) => {
        return (
          <MuiHeaderCell
            title="Weekly average"
            tooltipTitle={`${info} weekly average`}
          />
        );
      },
      id: "average",
      accessor: (d) => {
        return formatTableValue(
          d?.stats[DAYS_OF_WEEK.AVERAGE],
          col,
          d?.company_id
        );
      },
      Cell: (props) => {
        const { isTotal, company_id } = props.row.original;
        const companyId = isTotal ? props?.data[0]?.company_id : company_id;
        const isBroadway =
          col === "total_upsell_rate" && companyId === COMPANY_ID.BROADWAY;
        return (
          <>
            {isTotal ? (
              <Typography variant="body2" style={{ fontFamily: "RedHatBold" }}>
                {isBroadway
                  ? formatTotalUpsellValue(props?.value)
                  : props.value}
              </Typography>
            ) : (
              <>{props.value}</>
            )}
          </>
        );
      },
    },
  ];
};

function defaultSortBy(columnId) {
  return {
    sortBy: [{ id: columnId }],
    hiddenColumns: ["id"],
  };
}

export { getTableColumns, getTableTitle, defaultSortBy };
